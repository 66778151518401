<template>
  <div v-if="isVisible" :class="$style.component">
    <ul :class="classes.list" :key="isAnimating" :style="animationStyle">
      <carousel-item
        v-for="(image, index) in carouselImages"
        :index="index"
        :image="image"
        :class="$style.image"
        :key="`item-${index}`"
      />
    </ul>
  </div>
</template>

<script>
import CarouselItem from '@/components/carousel-item.vue'

export default {
  components: {
    CarouselItem
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    isVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isAnimating: false,
      imagesLoaded: 0,
      defaultDuration: 10, // Duration [s] that one image takes to transition across screen
      interval: null
    }
  },
  computed: {
    classes() {
      return {
        list: [
          this.$style.list,
          this.sliderVisible ? this.$style.sliderVisible : ''
        ]
      }
    },
    carouselImages() {
      // tripple amount of images to make sure slider reaches its end only after a long time (=> simple and temp workaround for infinitely looped carousel)
      return [...this.images, ...this.images, ...this.images]
    },
    firstSlide() {
      return this.currentIndex === 0
    },
    lastSlide() {
      return this.currentIndex === this.slideLength - 1
    },
    animationStyle() {
      const duration = this.defaultDuration * this.carouselImages.length
      const startOffset = (100 / this.carouselImages.length) * -1
      return {
        '--duration': duration + 's',
        '--start-offset': startOffset + '%'
      }
    },
    sliderVisible() {
      // Make sure the first two images are loaded before we show the slider
      return window.innerWidth >= 768
        ? this.imagesLoaded >= 2
        : this.imagesLoaded >= 1
    }
  },
  methods: {
    play() {
      setTimeout(() => {
        this.isAnimating = true
      }, 100)
    },

    prev() {
      if (this.firstSlide) {
        this.currentIndex = this.slideLength - 1
      } else {
        this.currentIndex--
      }
    },
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.slideLength
    }
  },
  mounted() {
    this.play()

    // Calculate correct sizes attribute
    document.addEventListener('lazybeforesizes', e => {
      const containerHeight = e.target.parentElement.offsetHeight
      const ratio = e.target.getAttribute('data-aspectratio')
      e.detail.width = Math.round(containerHeight * ratio)
    })

    document.addEventListener('lazyloaded', () => {
      this.imagesLoaded++
    })

    // TODO: Since lazysizes does not unveil images when they get into the viewport (without user interaction), unveil them programmatically
    this.interval = setInterval(() => {
      if (window.lazySizes.elements.length === 0) {
        // When there are no more images left to unveil, clear the interval
        clearInterval(this.interval)
      } else {
        // Since unveiled images get removed from the elements array, we just need to get the first index
        window.lazySizes.loader.unveil(window.lazySizes.elements[0])
      }
    }, this.defaultDuration * 1000 * 0.85) // Multiply by 0.85 to unveil image shortly before it gets into the screen
  },
  activated() {
    this.play()
  },
  deactivated() {
    this.isAnimating = false
    clearInterval(this.interval)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" module>
.component {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.list {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  // overflow-y: hidden;
  // display: grid;
  // grid-template-rows: 100%;
  // grid-auto-columns: auto;
  // grid-auto-flow: column;
  height: 100%;
  padding: var(--carousel-padding);
  opacity: 0;
  transition: opacity 1.25s ease;
  animation-name: t-slide-left;
  animation-duration: var(--duration);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: forward;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  will-change: transform;

  &.sliderVisible {
    opacity: 1;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

@keyframes t-slide-left {
  from {
    // Start animation slightly off right viewport side
    transform: translate3d(var(--start-offset), 0, 0);
  }

  to {
    // NOTE: 100% equals width of all images
    transform: translate3d(-100%, 0, 0);
  }
}
</style>
