<template>
  <li :class="classes.component" ref="component">
    <router-link :to="image.meta.url" :class="$style.link">
      <div :class="$style.image" :style="styles.image" @click="clickItem">
        <base-image :image="coverImage" :lazy="true" />
      </div>
    </router-link>
    <!-- <div v-if="image.title" v-html="image.title" :class="$style.description" /> -->
  </li>
</template>

<script>
// import EventBus from '@/event-bus'
import BaseImage from '@/components/base-image'

export default {
  components: {
    BaseImage
  },
  props: {
    image: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      required: true
    }
    // currentIndex: {
    //   type: Number,
    //   required: true
    // }
  },
  data() {
    return {
      isClicked: false,
      componentHeight: 0
    }
  },
  computed: {
    classes() {
      return {
        component: [
          this.$style.component,
          this.isClicked ? this.$style.isClicked : '',
          this.isHovered ? this.$style.isHovered : ''
        ]
      }
    },
    styles() {
      return {
        image: {
          width: this.imageWidth + 'px'
        }
      }
    },
    coverImage() {
      return this.image.fields.cover_image
        ? this.image.fields.cover_image[0]
        : null
    },
    imageWidth() {
      return Math.round(this.componentHeight * this.coverImage.ratio)
    }
    // isCurrent() {
    //   return this.index === this.currentIndex
    // },
    // isPortrait() {
    //   return this.image.ratio < 1
    // }
    // sizes() {
    //   return this.isPortrait
    //     ? '(min-width: 90em) 26vw, (min-width: 80em) 35vw, (min-width: 64em) 43vw, (min-width: 48em) 63vw, (min-width: 40em) 77vw, 100vw'
    //     : '(min-width: 90em) 72vw, (min-width: 80em) 83vw, (min-width: 64em) 101vw, (min-width: 48em) 83vw, 150vw'
    // }
  },
  methods: {
    clickItem() {
      // $event.preventDefault()
      this.isClicked = true
      // if (this.isClicked) {
      //   setTimeout(() => {
      //     this.$router.push(url)
      //   }, 450)
      // }
    },
    hoverItem() {
      this.isHovered = true
      // console.log(this.isHovered)
    },
    getComponentHeight() {
      this.componentHeight = this.$refs.component.offsetHeight
    }
  },
  mounted() {
    // if (this.isCurrent) EventBus.$on('intro-hidden', this.setNegative)
    this.$nextTick(this.getComponentHeight)
    window.addEventListener('resize', this.getComponentHeight)
  },
  watch: {
    isCurrent() {
      // if (this.isCurrent) this.setNegative()
    }
  },
  deactivated() {
    window.removeEventListener('resize', this.getComponentHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getComponentHeight)
  }
}
</script>

<style lang="scss" module>
.component {
  // display: flex;
  // flex: 0 0 auto;
  // flex-direction: column;
  // justify-content: center;
  width: 80%;
  mix-blend-mode: multiply;
  transition: transform 1s ease;

  @media #{$mqHover} {
    &:hover {
      transform: scale(0.7);
    }
  }

  // &:focus {
  //   transform: scale(0.7);
  // }

  &.isClicked {
    pointer-events: none;
    // transition: none;
    transform: scale(0.7);
  }

  // &.isHovered {
  //   // pointer-events: none;
  //   transform: scale(0.7);
  // }

  &:nth-child(7n + 1),
  &:nth-child(7n + 6) {
    height: 80%;
  }

  &:nth-child(7n + 2),
  &:nth-child(7n + 4) {
    height: 90%;
  }

  &:nth-child(7n + 3),
  &:nth-child(7n + 5),
  &:nth-child(7n + 7) {
    height: 100%;
  }

  &:not(:first-of-type) {
    margin-left: var(--carousel-item-indent);
  }

  &:not(:last-of-type) {
    margin-right: var(--carousel-item-indent);
  }
}

.link {
  display: block;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;

  > div {
    width: 100%;
    height: 100%;
  }

  img {
    width: auto;
    height: 100%;
  }
}
</style>
