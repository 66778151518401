<template>
  <main :class="$style.view" v-if="page.fields">
    <media-slider :media="page.fields.content" />

    <portal to="page-title" :disabled="disabled">
      <div v-html="page.fields.title" />
    </portal>
  </main>
</template>

<script>
import PageService from '@/services/page'
import MediaSlider from '@/components/media-slider.vue'

export default {
  components: { MediaSlider },
  metaInfo() {
    return {
      title: this.page.fields ? this.page.fields.title : ''
    }
  },
  data() {
    return {
      page: {},
      loading: true,
      disabled: false
    }
  },

  async created() {
    this.page = await PageService.get({ id: this.$route.name })
    this.loading = false
  },
  activated() {
    this.disabled = false
  },
  deactivated() {
    this.disabled = true
  }
  // mounted() {}
}
</script>

<style lang="scss" module>
.view {
  width: 100%;
  height: 100%;
}
</style>
