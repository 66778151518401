<template>
  <main :class="$style.view" v-if="page.fields">
    <carousel-list :images="images" :is-visible="isHomepage" />
    <news-bar :news="page.fields.news" />
  </main>
</template>

<script>
import { shuffleArray } from '@/mixins/shuffle-array'
import PageService from '@/services/page'
import CarouselList from '@/components/carousel-list.vue'
import NewsBar from '@/components/news-bar.vue'

export default {
  components: {
    CarouselList,
    NewsBar
  },
  mixins: [shuffleArray],
  metaInfo() {
    return {
      title: this.page.fields ? this.page.fields.title : ''
    }
  },
  data() {
    return {
      page: {},
      loading: true
    }
  },
  computed: {
    isHomepage() {
      return this.$route.name === 1
    },
    images() {
      return this.shuffleArray(this.page.fields.projects)
    }
  },
  async created() {
    this.page = await PageService.get({ id: this.$route.name })
    this.loading = false
  }
  // mounted() {}
}
</script>

<style lang="scss" module>
.view {
  width: 100%;
  height: 100%;
}
</style>
