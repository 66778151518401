<template>
  <component
    :is="type"
    :item="item"
    :class="$style.component"
    :id="`id-${itemId}`"
    :is-current="isCurrent"
  />
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import EventBus from '@/event-bus'

gsap.registerPlugin(ScrollTrigger)

export default {
  components: {
    RepeaterMatrixTypeImages: () =>
      import('@/components/repeater-matrix-type-images'),
    RepeaterMatrixTypeBody: () =>
      import('@/components/repeater-matrix-type-body'),
    RepeaterMatrixTypeQuote: () =>
      import('@/components/repeater-matrix-type-quote'),
    RepeaterMatrixTypeSpecs: () =>
      import('@/components/repeater-matrix-type-specs'),
    RepeaterMatrixTypeVideo: () =>
      import('@/components/repeater-matrix-type-video')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    currentIndex: {
      type: Number,
      default: () => null
    },
    hasCover: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      isScrolledTo: false,
      scrollTrigger: null
    }
  },
  computed: {
    isCurrent() {
      return this.currentIndex === this.itemId
    },
    itemId() {
      return this.hasCover ? this.index + 1 : this.index
    },
    type() {
      const type = this.item.type.replace(/\s+|_+/g, '-').toLowerCase()
      return `repeater-matrix-${type}`
    },
    scrollerOffset() {
      return window.innerWidth / 2 + 'px'
    }
  },
  methods: {
    emitCurrentIndex() {
      // send current scroll index (event bus due to use of dynamic component) to update index when user scrolls
      EventBus.$emit('scroll-index', this.itemId)
    },
    createScrollTrigger() {
      // Skip if scrollTrigger was already created
      if (this.scrollTrigger !== null) return
      this.scrollTrigger = ScrollTrigger.create({
        // markers: true,
        trigger: `#id-${this.itemId}`,
        start: 'left left+=' + this.scrollerOffset, // if left end of item touches left end of scroller (viewport by default) + offset to move start more to center
        end: 'right right-=' + this.scrollerOffset,
        horizontal: true,
        onToggle: ({ isActive }) => {
          if (isActive) {
            this.emitCurrentIndex()
          }
        }
      })
    },
    killScrollTrigger() {
      if (this.scrollTrigger) {
        this.scrollTrigger.kill()
        this.scrollTrigger = null
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.createScrollTrigger()
    }, 1000) // NOTE: time out is necessary to instantiate correct start and end positions. might needs adaption due to increased image content -> call refresh as soon as all media is loaded.
  },
  activated() {
    this.createScrollTrigger()
  },
  deactivated() {
    this.killScrollTrigger()
  },
  beforeDestroy() {
    this.scrollTrigger.kill()
  }
}
</script>

<style lang="scss" module>
.component {
  flex: 0 0 auto;
  max-width: 90vw;
  height: 100%;

  // "default" spacing for slide, is overridden for images in respective component
  padding: calc(var(--gutter) * 3.5) calc(var(--gutter) * 3.5)
    calc(var(--gutter) * 3.5) var(--gutter);

  &:last-of-type {
    align-items: center;
    justify-content: center;
    // width: 100vw;
    // max-width: 100vw;
    padding-right: 20vw;

    @media (min-width: $medium) {
      padding-right: 23vw;
    }
  }

  @media (min-width: $medium) {
    max-width: 90vw;
    padding: calc(var(--gutter) * 3.5) calc(var(--gutter) * 5)
      calc(var(--gutter) * 5) calc(var(--gutter) * 5);
  }
}
</style>
