<template>
  <main :class="$style.view" v-if="page.fields">
    <ul v-if="selectedCategory.name" :class="$style.sorted">
      <project-item
        v-for="(project, index) in page.children"
        :key="`project-${index}`"
        :project="project"
        :selected-category="selectedCategory"
        :sorted="true"
      />
    </ul>
    <ul v-else :class="$style.list">
      <project-item
        v-for="(project, index) in page.children"
        :key="`project-${index}`"
        :project="project"
        :selected-category="selectedCategory"
      />
    </ul>
    <category-filter
      :categories="projectCategories"
      @selected-category="handleSelectedCategory"
      :selected-category="selectedCategory"
    />

    <portal to="page-title" :disabled="disabled">
      <button
        v-html="page.fields.title"
        :class="$style.button"
        @click="resetFilter"
      />
    </portal>
  </main>
</template>

<script>
import PageService from '@/services/page'
import ProjectItem from '@/components/project-item.vue'
import CategoryFilter from '@/components/category-filter'
import EventBus from '@/event-bus'

export default {
  components: { ProjectItem, CategoryFilter },
  metaInfo() {
    return {
      title: this.page.fields ? this.page.fields.title : ''
    }
  },
  data() {
    return {
      page: {},
      loading: true,
      selectedCategory: {},
      projectsFiltered: false,
      disabled: false
    }
  },
  computed: {
    projectCategories() {
      let categories = []
      this.page.children.map(child => {
        // within each project item
        if (child.fields.project_categories.length < 1) return
        child.fields.project_categories.map(category => {
          // within each category of project
          const categoryTitle = category.fields.title
          // check if category already exists in array
          let categoryExists = false
          categories.find(pushedCategory => {
            if (pushedCategory.fields.title === categoryTitle) {
              categoryExists = true
            }
          })
          // if yes, return, else push it into array
          if (categoryExists) return
          categories.push(category)
        })
      })
      return categories
    }
  },
  methods: {
    // get selected category from category-filter
    handleSelectedCategory(category) {
      this.selectedCategory = category

      this.$router.replace({
        query: { category: this.selectedCategory.name }
      })
    },
    recallQuery() {
      if (!this.$route.query.category) return
      // get selectedCategory and assign it to make sure query is set
      this.selectedCategory.name = this.$route.query.category
      // send to site-header to use current category title as label for filter button
      EventBus.$emit('current-category', this.selectedCategory.name)
    },
    resetFilter() {
      // do not reset (and thus, cause a router error) if no category is selected
      if (Object.keys(this.selectedCategory).length === 0) return
      this.selectedCategory = {}
      // use this notion to make sure query object gets resetted completely
      let query = JSON.parse(JSON.stringify(this.$route.query))
      delete query.category
      // NOTE: does not seem to be necessary anymore
      // this.$router.replace({ query })
    }
  },
  async created() {
    this.page = await PageService.get({ id: this.$route.name })
    this.loading = false
  },
  mounted() {
    this.recallQuery()
  },
  activated() {
    this.disabled = false
    // make sure event is also emitted once page was loaded
    if (this.loading) return
    EventBus.$emit('show-filter-button', true)
  },
  deactivated() {
    this.disabled = true
    // reset visibility of filter when leaving view
    EventBus.$emit('show-filter-button', false)

    // reset category when route is left
    this.resetFilter()
  },
  watch: {
    loading() {
      if (!this.loading) {
        // send to site-header to handle visibility of filter button
        EventBus.$emit('show-filter-button', true)
      }
    },
    selectedCategory() {
      // flag is set as soon as selectedCategory is set => render list of filtered projects
      // for initial check to make sure filtered elements are visible when query exists
      Object.keys(this.selectedCategory).length > 0
        ? (this.projectsFiltered = true)
        : (this.projectsFiltered = false)
    }
  }
}
</script>

<style lang="scss" module>
.view {
  padding: calc(var(--blank-line) * 3) var(--gutter) var(--gutter);

  @media (min-width: $medium) {
    padding: calc(var(--blank-line) * 2) var(--gutter) var(--gutter);
  }
}

.sorted {
  margin-bottom: calc(var(--blank-line) * 5);
}

.button {
  text-shadow: var(--shadow-nav);
}
</style>
