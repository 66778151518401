<template>
  <main :class="$style.view" v-if="page.fields">
    <media-slider
      :media="page.fields.content"
      :cover="page.fields.cover_image"
      :title="page.fields.title"
    />
    <portal to="page-title" :disabled="disabled">
      <router-link to="/projects/" :class="$style.link">
        <span :class="classes.parentTitle">{{ $t('parentTitle') }}</span>
      </router-link>
    </portal>

    <portal to="project-title" :disabled="disabled" :order="1">
      <span v-html="page.fields.title" :class="classes.projectTitle" />
    </portal>
  </main>
</template>

<script>
import PageService from '@/services/page'
import MediaSlider from '@/components/media-slider.vue'
import { mapState } from 'vuex'

export default {
  components: { MediaSlider },
  metaInfo() {
    return {
      title: this.page.fields ? this.page.fields.title : ''
    }
  },
  data() {
    return {
      page: {},
      loading: true,
      disabled: false
    }
  },
  computed: {
    ...mapState(['navVisible', 'filterVisible']),
    classes() {
      return {
        parentTitle: [
          this.$style.parentTitle,
          this.navVisible ? this.$style.navVisible : '',
          this.filterVisible ? this.$style.filterVisible : ''
        ],
        projectTitle: [
          this.$style.projectTitle,
          this.navVisible ? this.$style.navVisible : '',
          this.filterVisible ? this.$style.filterVisible : ''
        ]
      }
    }
  },
  async created() {
    this.page = await PageService.get({ id: this.$route.name })
    this.loading = false
  },
  activated() {
    this.disabled = false
  },
  deactivated() {
    this.disabled = true
  }
}
</script>

<style lang="scss" module>
.view {
  width: 100%;
  height: 100%;
}

.link {
  @extend %link-reset;

  display: inline-block;
}

.parentTitle,
.projectTitle {
  color: var(--black);
  text-shadow: var(--shadow-nav);
  pointer-events: none;
  transition: color 450ms ease, text-shadow 450ms ease;

  &.navVisible,
  &.filterVisible {
    color: transparent;
    text-shadow: 0 0 0 transparent;
  }
}
</style>
