import Vue from 'vue'
import Vuex from 'vuex'
import { addRoutesFromApi } from '@/router'
import EventBus from '@/event-bus'
import ApiService from '@/services/api'
import i18n from '@/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiRoutes: [],
    availableLanguages: [],
    availableLanguagesFallback:
      process.env.VUE_APP_AVAILABLE_LANGUAGES_FALLBACK,
    currentLanguage: process.env.VUE_APP_I18N_LOCALE,
    initialized: false,
    metaDescription: undefined,
    siteTitle: undefined,
    claim: undefined,
    contact: undefined,
    breakpoints: {
      xxsmall: window.matchMedia('(min-width: 23.4375em)'),
      medium: window.matchMedia('(min-width: 48em)')
      // medium: window.matchMedia('(orientation: landscape)')
    },
    isXxsmall: false,
    isMedium: false,
    navVisible: false,
    filterVisible: false
  },
  mutations: {
    setFilterVisibility(state, payload) {
      state.filterVisible = payload
    },
    setNavVisibility(state, payload) {
      state.navVisible = payload
    },
    set(state, data) {
      Object.entries(data).forEach(([key, value]) => {
        state[key] = value
      })
    },
    setLanguage(state, payload) {
      state.currentLanguage = localStorage.lang = i18n.locale = payload.lang
      EventBus.$emit('language-change')
    },
    recallLanguage(state, payload) {
      const languages = state.availableLanguagesFallback.split(',')
      const segmentsRaw = payload.to.fullPath.split('/')
      const segments = segmentsRaw.filter(segment => segment != '')
      const urlLang = segments[0]

      if (languages.includes(urlLang)) {
        state.currentLanguage = i18n.locale = urlLang
        document.documentElement.setAttribute('lang', urlLang)
      }
    },
    updateBreakpoints(state) {
      // Use this function to test if a certain breakpoint matches and to define what should happen next
      state.isXxsmall = state.breakpoints.xxsmall.matches
      state.isMedium = state.breakpoints.medium.matches
    }
  },

  actions: {
    async getDefaults({ commit, state }, payload) {
      commit('recallLanguage', payload)
      const { data } = await ApiService.get('defaults/' + state.currentLanguage)
      commit('set', data)
      commit('set', { initialized: true })

      addRoutesFromApi(data.apiRoutes)
    },
    // Set up handlers for all defined breakpoints
    initBreakpoints({ commit, state }) {
      Object.keys(state.breakpoints).forEach(key => {
        // Check breakpoint initially
        // commit('updateBreakpoints', state.breakpoints[key])
        commit('updateBreakpoints')
        // Add event listener to breakpoint
        state.breakpoints[key].addListener(() => {
          commit('updateBreakpoints')
        })
      })
    }
  }
})
