<template>
  <div
    v-if="!resizeImage && lazy"
    :class="classes.container"
    :style="styles.container"
  >
    <img
      :data-src="src"
      :data-parent-fit="parentFit"
      :data-aspectratio="image.ratio"
      :alt="image.description"
      :class="classes.image"
    />
  </div>
  <img
    v-else-if="!resizeImage"
    :src="src"
    :alt="image.description"
    :class="classes.image"
    @load="onLoaded"
  />
  <div v-else-if="lazy" :class="classes.container" :style="styles.container">
    <img
      :data-srcset="srcset"
      :data-parent-fit="parentFit"
      :data-aspectratio="image.ratio"
      :alt="image.description"
      :class="classes.image"
      sizes="auto"
    />
  </div>
  <img
    v-else
    :srcset="srcset"
    :sizes="sizes"
    :alt="image.description"
    :class="classes.image"
    @load="onLoaded"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    image: {
      type: Object,
      required: true
    },
    sizes: {
      type: String,
      default: '100vw'
    },
    lazy: {
      type: Boolean,
      default: false
    },
    parentFit: {
      type: String,
      default: '' // contain|cover|width
    },
    preserveSpace: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    classes() {
      return {
        container: [
          this.$style.container,
          this.preserveSpace ? this.$style.preserveSpace : ''
        ],
        image: [
          this.$style.image,
          this.loaded ? 'is-loaded' : '',
          this.isPortrait ? 'is-portrait' : '',
          this.lazy ? 'lazyload' : ''
        ]
      }
    },
    styles() {
      return {
        container: [
          this.preserveSpace ? { 'padding-bottom': this.paddingBottom } : ''
        ]
      }
    },
    paddingBottom() {
      return (100 / this.image.ratio).toFixed(2) + '%'
    },
    isPortrait() {
      return this.image.ratio < 1
    },
    resizeImage() {
      return this.image.ext !== 'svg'
    },
    src() {
      return this.image.urls[0].url
    },
    srcset() {
      let response = ''
      Object.values(this.image.urls).forEach(url => {
        response += `${url.url} ${url.width}w,`
      })
      response = response.slice(',', -1)
      return response
    }
  },
  methods: {
    onLoaded() {
      this.loaded = true
    }
  }
}
</script>

<style lang="scss" module>
.container {
  &.preserveSpace {
    position: relative;
  }
}

.image {
  opacity: 0;
  transition: opacity 150ms ease;

  .preserveSpace & {
    position: absolute;
  }

  &:global(.is-loaded),
  &:global(.lazyloaded) {
    opacity: 1;
  }
}
</style>
