<template>
  <li :class="$style.component">
    <div :class="classes.image">
      <base-image :image="feature.fields.image[0]" :sizes="sizes" />
    </div>
    <div :class="$style.text">
      <!-- <div
        v-for="(category, index) in feature.fields.post_category"
        :key="`post-${index}`"
        v-html="category.fields.title"
        :class="$style.category"
      /> -->
      <div v-if="feature.fields.post_category.length" :class="$style.category">
        {{ categoryItem.fields.title }}
      </div>
      <div v-html="feature.fields.title" :class="$style.title" />
      <div
        v-html="feature.fields.publication_date.formatted.date"
        :class="$style.date"
      />
      <div
        v-if="feature.fields.location"
        v-html="feature.fields.location"
        :class="$style.location"
      />
      <base-bodytext :text="feature.fields.body" :class="$style.body" />

      <a
        v-if="feature.fields.external_url"
        :href="feature.fields.external_url"
        :class="$style.url"
        >{{ $t('website') }}</a
      >
    </div>
  </li>
</template>

<script>
import BaseImage from '@/components/base-image'
import BaseBodytext from '@/components/base-bodytext'

export default {
  components: { BaseImage, BaseBodytext },
  props: {
    feature: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    classes() {
      return {
        image: [this.$style.image, this.isPortrait ? 'isPortrait' : '']
      }
    },
    isPortrait() {
      return this.feature.fields.image[0].ratio < 1 ? true : false
    },
    sizes() {
      return this.isPortrait
        ? '(min-width: 40em) 40vw, 71vw'
        : ' (min-width: 40em) 40vw, 100vw'
    },
    categoryItem() {
      if (!this.feature.fields.post_category.length) return
      const categories = this.feature.fields.post_category
      return categories[categories.length - 1]
    }
  }
}
</script>

<style lang="scss" module>
.component {
  margin-bottom: calc(var(--blank-line) * 2);

  @media (min-width: $small) {
    display: flex;
  }

  @media (min-width: $medium) {
    margin-bottom: var(--blank-line);
  }
}

.image {
  width: 100%;
  margin-right: var(--gutter);
  margin-bottom: calc(var(--blank-line) / 2);

  &:global(.isPortrait) {
    width: 75%;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media (min-width: $small) {
    width: 40%;

    &:global(.isPortrait) {
      width: 40%;
    }
  }

  @media (min-width: $medium) {
    &:global(.isPortrait) {
      // width: auto;
      max-height: 75vh;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.text {
  @media (min-width: $small) {
    flex: 1;
  }
}

.category,
.title,
.date,
.location {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-medium;

  text-transform: lowercase;
}

.body {
  max-width: 80%;
  margin-top: var(--blank-line);
}

.url {
  @extend %link-reset;
  @extend %link-hover;
  @extend %fw-bold;

  display: inline-block;
  margin-top: var(--blank-line);
}
</style>
