<template>
  <div :class="$style.component">
    <base-image :image="image.image" :sizes="sizes" />
  </div>
</template>

<script>
import BaseImage from '@/components/base-image'
import { mapState } from 'vuex'

export default {
  components: { BaseImage },
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['isMedium']),
    sizes() {
      return (window.innerHeight / 8) * this.image.image.ratio + 'px'
    }
  }
}
</script>

<style lang="scss" module>
.component {
  display: block;
  height: 100%;
}
</style>
