<template>
  <transition name="t-fade">
    <div v-if="filterVisible" :class="$style.component">
      <!-- <div :class="$style.header"> -->
      <button type="button" :class="$style.close" @click="closeOverlay">
        <span :class="$style.label"> {{ $t('close') }}</span>
      </button>
      <div :class="$style.info">{{ $t('filterTitle') }}</div>
      <!-- </div> -->

      <ul :class="$style.categories">
        <li :class="$style.reset" @click="resetFilter">{{ $t('all') }}</li>
        <li
          v-for="(category, index) in categories"
          :key="`category-${index}`"
          v-html="category.fields.title"
          :class="$style.category"
          @click="handleClick(category)"
        />
      </ul>
    </div>
  </transition>
</template>

<script>
import EventBus from '@/event-bus'
import { mapState } from 'vuex'

export default {
  components: {},
  props: {
    categories: {
      type: Array,
      required: true
    },
    selectedCategory: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentCategory: {}
    }
  },
  computed: {
    ...mapState(['filterVisible'])
  },
  methods: {
    closeOverlay() {
      this.$store.commit('setFilterVisibility', false)
    },
    handleClick(category) {
      this.currentCategory = category.fields
    },
    resetFilter() {
      this.currentCategory = {}
    }

    // scrollToTop() {
    //   const options = {
    //     easing: 'ease'
    //   }
    //   this.$scrollTo('#top', 0, options)
    // }
  },
  // updated() {
  //   this.scrollToTop()
  // },
  watch: {
    selectedCategory: {
      deep: true,
      handler() {
        this.currentCategory = this.selectedCategory
      }
    },
    currentCategory: {
      deep: true,
      handler() {
        // send to projects
        this.$emit('selected-category', this.currentCategory)

        // send to site-header to use current category title as label for filter button
        EventBus.$emit('current-category', this.currentCategory.title)
        this.closeOverlay()
      }
    }
    // selectedCategory() {
    //   this.currentCategory = this.selectedCategory
    //   // if (!this.selectedCategory) {
    //   //   this.currentCategory =
    //   // }
    // },
    // currentCategory() {}
  }
}
</script>

<style lang="scss" scoped>
@include t-fade('t-fade');
</style>

<style lang="scss" module>
.component {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: var(--gutter);
  overflow-y: auto;
  color: var(--black);
  background-color: var(--white-alpha);
}

.header {
  display: flex;
  flex-direction: column;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: var(--gutter);
  text-transform: uppercase;
  cursor: pointer;
}

.label {
  @extend %fs-nav;
}

.close,
.info,
.category,
.reset {
  @extend %ff-sans;
  @extend %fw-bold;
}

.info,
.category,
.reset {
  text-transform: lowercase;
}

.info,
.category,
.reset {
  @extend %fs-medium;
}

.info {
  margin-top: -0.2em;
  margin-bottom: var(--blank-line);
}

.category,
.reset {
  cursor: pointer;
}

.reset {
  color: var(--black);
}
</style>
