<template>
  <li :class="$style.component">
    <div v-html="item.title" :class="$style.title" />
    <ul :class="$style.list">
      <post-item
        v-for="(post, index) in item.children"
        :key="`post-${index}`"
        :post="post"
      />
    </ul>
  </li>
</template>

<script>
import PostItem from '@/components/post-item'

export default {
  components: { PostItem },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    isCurrentYear() {
      return parseInt(this.item.title) === this.currentYear
    }
  }
}
</script>

<style lang="scss" module>
.component {
  // margin-bottom: calc(var(--blank-line) * 2);

  @media (min-width: $medium) {
    margin-bottom: var(--blank-line);
  }
}

.title {
  @extend %ff-sans;
  @extend %fw-medium;
  @extend %fs-xsmall;

  margin-bottom: var(--blank-line);
}
// .component {
// &:not(:last-of-type) {
//   margin-bottom: calc(var(--blank-line) * 2);
// }

// @media (orientation: landscape) {
//   display: flex;
// }
// }

// .title {
//   max-width: var(--text-width);
//   margin-bottom: var(--blank-line);

//   @media (orientation: landscape) {
//     width: calc(var(--gutter) * 4);
//     padding-right: var(--gutter);
//   }
// }

// .body {
//   max-width: var(--text-width);
//   margin-left: calc(var(--gutter) * 2);

//   // override default styling of base-bodytext
//   p {
//     position: relative;
//     margin-left: var(--list-indent);
//     text-align: start;

//     &:not(:last-child) {
//       margin-bottom: 0;
//     }

//     &::before {
//       position: absolute;
//       margin-left: calc(var(--list-indent) * -2);
//       content: '·';
//     }
//   }

//   @media (orientation: landscape) {
//     flex: 1;
//     margin-left: unset;
//   }
// }
</style>
