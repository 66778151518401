<template>
  <div v-if="news" :class="$style.component">
    <router-link
      to="discourse/"
      v-if="news.post[0].meta.url"
      :class="$style.link"
    >
      <div :class="$style.wrapper">
        <div v-html="news.title" :class="$style.title" />
        <div v-html="news.body" :class="$style.body" />
      </div>
    </router-link>
  </div>
</template>

<script>
import EventBus from '@/event-bus'

export default {
  // components: {},
  props: {
    news: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  mounted() {
    // get from about-overlay
    EventBus.$on('overlay-visible', payload => {
      this.isVisible = payload
    })
  }
}
</script>

<style lang="scss" scoped>
@include t-fade;
</style>

<style lang="scss" module>
.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: 0 var(--gutter) var(--gutter) var(--gutter);
}

.link {
  @extend %link-reset;
}

.title,
.body {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-news;

  text-transform: lowercase;
}

.title {
  flex: 1;
  margin-right: calc(var(--gutter) / 2);

  @media (min-width: $small) {
    flex: initial;
  }
}

.body {
  flex: 2;

  @media (min-width: $small) {
    flex: initial;
  }
}
</style>
