<template>
  <li v-if="showProject" :class="$style.component">
    <div :class="$style.images">
      <router-link
        :to="project.meta.url"
        v-for="(image, index) in projectImages"
        :key="`image-${index}`"
        :class="$style.link"
      >
        <project-image :image="image" />
      </router-link>
    </div>
    <div v-html="project.fields.title" :class="$style.title" />
  </li>
</template>

<script>
import ProjectImage from '@/components/project-image'
import { mapState } from 'vuex'

export default {
  components: { ProjectImage },
  props: {
    project: {
      type: Object,
      required: true
    },
    selectedCategory: {
      type: Object,
      default: () => null
    },
    sorted: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['isMedium']),
    // check if passed category exists within project
    hasSelectedCategories() {
      if (!this.selectedCategory) return
      let categoryExists = false
      // const categoryExists = this.project.fields...
      // cannot be changed to return value of find bc this is not a boolean. using includes leads to wrong results
      this.project.fields.project_categories.find(category => {
        if (category.fields.name === this.selectedCategory.name) {
          categoryExists = true
        }
      })
      return categoryExists
    },
    // based on passed prop and flag, evaluate rendering of component. NOTE: does not work when passed up to parent as it does not store flag for each project specifically
    showProject() {
      return (
        // if item should be rendered in sorted list, then only when category was set. everything else leads to false
        // or if item should not be rendered in sorted list, then only show item if no matching category was evaluated
        (this.sorted && this.hasSelectedCategories) ||
        (!this.sorted && !this.hasSelectedCategories)
      )
    },
    projectImages() {
      let images = []
      const cover = this.project.fields.cover_image[0]
      const coverObj = { image: cover, portrait: this.isPortrait(cover) }
      images.push(coverObj)

      this.project.fields.content.map(item => {
        if (item.type === 'type_images') {
          item.images.map(image => {
            const orientation = this.isPortrait(image)
            const imgObj = { image: image, portrait: orientation }
            images.push(imgObj)

            if (!this.isMedium) {
              images.splice(5)
            }
          })
        }
      })
      return images
    }
  },
  methods: {
    // handleClick() {
    //   this.$router.push()
    // },
    isPortrait(image) {
      return image.ratio < 1 ? true : false
    },
    sizes(image) {
      return Math.round((window.innerHeight / 8) * image.image.ratio) + 'px'
    }
  }
}
</script>

<style lang="scss" module>
.component {
  &:not(:last-of-type) {
    margin-bottom: calc(var(--blank-line) * 1.5);
  }
}

.images {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // margin-bottom: var(--item-image-margin);
}

.link {
  height: calc(100vh / 8.5);
  margin-bottom: var(--item-image-margin);
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: var(--item-image-margin);
  }

  img {
    width: auto;
    height: 100%;
  }

  @media (min-width: $medium) {
    height: calc(100vh / 8);
  }
}

.title {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-xsmall;

  text-transform: uppercase;
}

// .link {
//   display: flex;
//   flex-wrap: wrap;
// }
</style>
