import Vue from 'vue'
import App from '@/app.vue'
import router from '@/router'
import store from '@/store'
import '@/plugins'
import ApiService from '@/services/api'
import VueScrollTo from 'vue-scrollto'
import PortalVue from 'portal-vue'
import i18n from '@/i18n'

Vue.use(PortalVue)
Vue.use(VueScrollTo)

// import VueScrollmagic from 'vue-scrollmagic'

// Vue.use(VueScrollmagic, {
//   vertical: true,
//   globalSceneOptions: {},
//   loglevel: 2,
//   refreshInterval: 100
// })

Vue.config.productionTip = false

ApiService.init(process.env.VUE_APP_API_URL)

// get api routes and init data before everything else:
router.beforeEach(async (to, from, next) => {
  if (store.state.initialized) {
    return next()
  }
  await store.dispatch('getDefaults', { to: to })
  next({
    path: to.fullPath,
    replace: true
  })
})

const main = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default main
