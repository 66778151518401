<template>
  <li :class="$style.component">
    <div :class="$style.image">
      <base-image :image="image" :sizes="sizes" />
    </div>

    <div :class="$style.text">
      <div v-if="post.fields.post_category.length" :class="$style.category">
        {{ categoryItem.fields.title }}
      </div>
      <div v-html="post.fields.title" :class="$style.title" />

      <a
        :href="post.fields.pdf"
        v-if="post.fields.pdf"
        :class="$style.pdf"
        target="_blank"
        download
      >
        <span :class="$style.label">{{ $t('download') }}</span>
      </a>
      <a
        :href="post.fields.external_url"
        v-if="post.fields.external_url"
        :class="$style.link"
        target="_blank"
        download
      >
        <span :class="$style.label">{{ $t('more') }}</span>
      </a>
    </div>
  </li>
</template>

<script>
import BaseImage from '@/components/base-image'

export default {
  components: { BaseImage },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    image() {
      return this.post.fields.image && this.post.fields.image.length
        ? this.post.fields.image[0]
        : null
    },
    sizes() {
      return Math.round((window.innerHeight / 4) * this.image.ratio) + 'px'
    },
    categoryItem() {
      if (!this.post.fields.post_category.length) return
      const categories = this.post.fields.post_category
      return categories[categories.length - 1]
    }
  }
}
</script>

<style lang="scss" module>
.component {
  display: inline-block;
  margin-bottom: var(--blank-line);

  @media (min-width: $xxsmall) {
    display: inline-grid;
    grid-template-rows: minmax(0, 1fr) auto;
    width: min-content; // prevents text from exeeding image width

    &:not(:last-of-type) {
      // margin-right: var(--item-image-margin);
      margin-right: var(--gutter);
    }
  }
}

.image {
  width: 100%;
  margin-bottom: calc(var(--blank-line) / 2);

  :global(.is-portrait) {
    width: 75%;
  }

  @media (min-width: $xxsmall) {
    height: calc(100vh / 4);

    :global(.is-portrait) {
      width: auto;
    }

    img {
      width: auto;
      height: 100%;
    }
  }
}

.link {
  @extend %link-reset;

  display: inline-block;
}

.text {
  display: flex;
  flex-direction: column;
}

.title,
.category,
.link,
.pdf {
  @extend %ff-sans;
  @extend %fw-medium;
  @extend %fs-xsmall;
}

.category {
  text-transform: uppercase;
}

.pdf {
  @extend %link-reset;

  display: inline-block;
  // margin-top: var(--blank-line);
}

.label {
  border-bottom: solid 1px var(--black);
}
</style>
