<template>
  <section :class="$style.component">
    <div :class="$style.slider" id="project-slider">
      <div v-if="hasCover" :class="$style.cover" key="cover" :id="'id-0'">
        <base-image :image="cover[0]" :sizes="sizes" />
        <transition name="t-section">
          <div v-if="showSectionTitle" :class="$style.section">
            <span v-html="title" :class="$style.title" />
          </div>
        </transition>
      </div>
      <repeater-matrix-item
        v-for="(item, index) in media"
        :index="index"
        :current-index="currentIndex"
        :item="item"
        :has-cover="hasCover"
        :key="`item-${index}`"
      />
    </div>

    <button :class="classes.prev" @click="prev" />
    <button :class="classes.next" @click="next" />

    <portal to="project-title" :order="2" :disabled="disabled">
      <span v-if="showCounter" :class="classes.counter">
        <span>({{ formattedIndex }}/</span>
        <span>{{ slideLength }})</span>
      </span>
    </portal>
  </section>
</template>

<script>
import RepeaterMatrixItem from '@/components/repeater-matrix-item.vue'
import BaseImage from '@/components/base-image'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import EventBus from '@/event-bus'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { mapState } from 'vuex'

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger)

export default {
  components: {
    RepeaterMatrixItem,
    BaseImage
  },
  props: {
    media: {
      type: Array,
      default: () => []
    },
    cover: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      currentIndex: 0,
      duration: 0.8,
      scrollTrigger: null,
      disabled: false
    }
  },
  computed: {
    ...mapState['navVisible'],
    classes() {
      return {
        prev: [
          this.$style.prev,
          this.firstSlide ? this.$style.firstSlide : '',
          this.$style.prev,
          this.lastSlide ? this.$style.lastSlide : ''
        ],
        next: [
          this.$style.next,
          this.firstSlide ? this.$style.firstSlide : '',
          this.$style.next,
          this.lastSlide ? this.$style.lastSlide : ''
        ],
        counter: [
          this.$style.counter,
          this.navVisible ? this.$style.navVisible : ''
        ]
      }
    },
    sizes() {
      return this.isPortrait ? '100vw' : '300vw'
    },
    showCounter() {
      return this.currentIndex && this.$route.meta.template === 'project'
    },
    formattedIndex() {
      return this.currentIndex === 0 ? null : this.currentIndex + 1
    },
    coverIsCurrent() {
      return this.currentIndex === 0
    },
    showSectionTitle() {
      return this.coverIsCurrent && this.title
    },
    slideLength() {
      return this.hasCover ? this.media.length + 1 : this.media.length
    },
    offsetX() {
      const currentSlide = document.getElementById(`id-${this.currentIndex}`)
      return (window.innerWidth - currentSlide.offsetWidth) / 2
    },
    hasCover() {
      return this.cover && this.cover.length > 0
    },
    firstSlide() {
      return this.currentIndex === 0
    },
    lastSlide() {
      return this.currentIndex === this.slideLength - 1
    },
    currentItem() {
      return this.media[this.currentIndex]
    },
    scrollerOffset() {
      return window.innerWidth / 2 + 'px'
    }
  },
  methods: {
    createScrollTrigger() {
      // Skip if scrollTrigger was already created
      if (this.scrollTrigger !== null) return
      this.scrollTrigger = ScrollTrigger.create({
        // markers: true,
        trigger: `#id-0`,
        start: 'left left+=' + this.scrollerOffset, // if left end of item touches left end of scroller (viewport by default) + offset to move start more to center
        end: 'right right-=' + this.scrollerOffset,
        horizontal: true,
        onToggle: ({ isActive }) => {
          if (isActive) {
            this.currentIndex = 0
          }
        }
      })
    },
    killScrollTrigger() {
      if (this.scrollTrigger) {
        this.scrollTrigger.kill()
        this.scrollTrigger = null
      }
    },
    handleScrollIndex(index) {
      this.currentIndex = index
    },
    prev() {
      if (this.firstSlide) return
      this.currentIndex--

      gsap.to('#project-slider', {
        duration: this.duration,
        scrollTo: {
          x: `#id-${this.currentIndex}`,
          autoKill: true,
          offsetX: this.offsetX
        }
      })
      // this.onEnteredSlide = false
    },
    next() {
      if (this.lastSlide) return
      this.currentIndex = (this.currentIndex + 1) % this.slideLength

      gsap.to('#project-slider', {
        duration: this.duration,
        scrollTo: {
          x: `#id-${this.currentIndex}`,
          autoKill: true,
          offsetX: this.offsetX
        }
      })
      // this.onEnteredSlide = false
    }
  },
  mounted() {
    setTimeout(() => {
      this.createScrollTrigger()
    }, 2000) // NOTE: time out is necessary to instantiate correct start and end positions. might needs adaption due to increased image content -> call refresh as soon as all media is loaded.

    // get current scroll index from child (event bus due to use of dynamic component) to update index when user scrolls
    EventBus.$on('scroll-index', payload => {
      this.currentIndex = payload
    })
  },
  activated() {
    this.createScrollTrigger()
    this.disabled = false
  },
  deactivated() {
    this.killScrollTrigger()
    this.disabled = true
  },
  beforeDestroy() {
    this.scrollTrigger.kill()
  }
}
</script>

<style lang="scss" scoped>
.t-section-enter-active,
.t-section-leave-active {
  transition: opacity var(--long) linear;
}

// to avoid flashing when there are two identicla section titles
.t-section-leave-active {
  transition-delay: var(--xshort);
}

.t-section-enter,
.t-section-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" module>
.component {
  // width: 100%;
  height: 100%;
}

.slider {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: inherit;
  overflow-x: scroll;

  @media (min-width: $small) {
    align-items: initial;
  }
}

.cover {
  flex: 0 0 auto;
  width: 100vw;
  height: 100%;
  margin-right: var(--gutter);

  @media (min-width: $medium) {
    margin-right: initial;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.section {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: var(--gutter);
  margin-bottom: -0.3em; // to align section title with lower border of caption
  text-shadow: var(--shadow-section);
}

.title {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-large;

  text-transform: lowercase;
}

.prev,
.next {
  position: fixed;
  top: 0;
  z-index: 1;
  display: block;
  width: 50%;
  height: 100%;
}

.prev {
  left: 0;
  cursor: url('../assets/images/arrow-left.png') 20 18, w-resize; // Legacy

  &.firstSlide {
    display: none;
  }

  &.lastSlide {
    width: 100%;
  }
}

.next {
  right: 0;
  cursor: url('../assets/images/arrow-right.png') 20 18, e-resize; // Legacy

  &.firstSlide {
    width: 100%;
  }

  &.lastSlide {
    display: none;
  }
}

.counter {
  margin-left: var(--gutter);
  text-shadow: var(--shadow-nav);
  transition: text-shadow 450ms ease;

  &.navVisible {
    text-shadow: 0 0 0 transparent;
  }
}
</style>
