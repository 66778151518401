<template>
  <main :class="$style.view" v-if="page.fields">
    <ul v-if="hasFeatures">
      <featured-post
        v-for="(feature, index) in featuredPosts"
        :key="`feature-${index}`"
        :feature="feature"
      />
    </ul>
    <ul v-if="hasPosts" :class="$style.posts">
      <discourse-year
        v-for="(item, index) in page.children"
        :key="`year-${index}`"
        :item="item"
      />
    </ul>

    <portal to="page-title" :disabled="disabled">
      <div v-html="page.fields.title" />
    </portal>
  </main>
</template>

<script>
import PageService from '@/services/page'
import DiscourseYear from '@/components/discourse-year'
import FeaturedPost from '@/components/featured-post'

export default {
  components: { DiscourseYear, FeaturedPost },
  metaInfo() {
    return {
      title: this.page.fields ? this.page.fields.title : ''
    }
  },
  data() {
    return {
      page: {},
      loading: true,
      disabled: false
    }
  },
  computed: {
    hasFeatures() {
      return this.page.featuredPost.length > 0
    },
    hasPosts() {
      return this.page.children.length > 0
    },
    featuredPosts() {
      let features = []
      this.page.featuredPost.map(feature => {
        const featureId = feature.meta.id

        this.page.children.map(child => {
          child.children.map(subchild => {
            if (subchild.meta.id === featureId) {
              features.push(subchild)
            }
          })
        })
      })
      return features
    }
  },
  async created() {
    this.page = await PageService.get({ id: this.$route.name })
    this.loading = false
  },
  activated() {
    this.disabled = false
  },
  deactivated() {
    this.disabled = true
  }
  // mounted() {}
}
</script>

<style lang="scss" module>
.view {
  width: 100%;
  padding: calc(var(--blank-line) * 3) var(--gutter) var(--gutter);
}
</style>
